import React from "react";
import MetaTags from 'react-meta-tags';

export default () => {
    return (
        <div class="wrapper">
          <MetaTags>
            <title>ABC27 News: Mechanicsburg area startup company gets funding</title>
            <meta
      property="og:title"
      content="ABC27 News: Mechanicsburg area startup company gets funding"
    />
          </MetaTags>
         </div>
      )
  }

import React, {useState} from "react";
import { jobs,availability } from "utils/jobs";
import axios from "axios"
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import "./form.css"
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
// import EmailIllustrationSrc from "images/email-illustration.svg";
import EnvIllustrationSrc from "images/env-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12  mt-16 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const CheckboxContent = tw.div`mt-3 block`;
// const Checkbox = tw.label`mt-6 mr-6 inline-flex text-orange-600 items-center hover:cursor-pointer`
// const Chek = tw.input`absolute opacity-0 h-0 w-0 inline-flex text-orange-600 items-center hover:cursor-pointer bg-primary-900`
// const Span = tw.span`ml-3`
const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Error = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-red-700`

const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-5 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
//const CheckInput = tw.input`first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`


const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

//const ScriptUri = "https://script.google.com/a/macros/gigandtake.com/s/AKfycbwl07svzz4z_1n-WN17VD15emDQK4XAvfwfAlZykaHremMJMHD-kzFgSnLJ7FATJmO1/exec"

export default ({
  subheading = "Register",
  heading = <>Sign up here for  <span tw="text-primary-500">flexible jobs.</span><wbr/></>,
  description = <><span tw="text-primary-500">Thanks! </span><wbr/> Your form is submitted successfully.</>,
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "post",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [jobCheckedState, setJobCheckedState] = useState(
    new Array(jobs.length).fill(false)
  );

  const [availCheckedState,setAvailCheckedState] = useState(
    new Array(availability.length).fill(false)
  )

  const [jobList,setJobList] = useState([])
  const [availList,setAvailList] = useState([])
    const [error,setError] = useState(false)
  const [data,setData] = useState({name:"",phone:"",zipcode:"",email:""})

  const [isSubmit,setIsSubmit] = useState(false)

  const handleInputChange = (e) => {
    setData({...data,[e.target.name]:e.target.value})
  }

  const handleJobChange = (position) => {
    const updatedCheckedState = jobCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setJobCheckedState(updatedCheckedState);
    
    const newJobs = updatedCheckedState.reduce(
      (arr, currentState, index) => {
        if (currentState === true) {
          return [...arr,jobs[index].name];
        }
        return arr;
      },
      []
    );
    setJobList(newJobs)
  }

  const handleAvailChange = (position) => {
    const updatedCheckedState = availCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setAvailCheckedState(updatedCheckedState);
    
    const newAvail = updatedCheckedState.reduce(
      (arr, currentState, index) => {
        if (currentState === true) {
          return [...arr,availability[index].name];
        }
        return arr;
      },
      []
    );
    setAvailList(newAvail)
  }

  const formSubmit = async(e) => {
    e.preventDefault()
    let a = data['phone'].replace(/\D/g,'').length === 10
    // console.log(a)
    if(a===false){
      setError(true)
    }else{
      if(data['zipcode'].length===0 || data['phone'].length===0){
        // console.log(data.zipcode,data.phone)
        setError(true)
      }else{
        const val = {...data,jobs:jobList,availability:availList,datetime:new Date().toLocaleString()}
        // console.log("submit",val)
        try {
          const res = await axios.post('https://sheet.best/api/sheets/848f3c4c-128e-453e-97a6-f8fd2a7038d2',val)
          // console.log(res)
          if(res.status===200) setIsSubmit(true)
          setData({...data,name:"",phone:"",zipcode:""})
          setError(false)
        } catch (error) {
          console.error(error)
        }
      }
    }
    
  }



  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          {isSubmit && <TextContent><Heading>{description}</Heading></TextContent>}
          {!isSubmit && <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Form>
              <Input type="text" name="name" placeholder="Full Name*" onChange={handleInputChange} required />
              <Input type="tel" name="phone" placeholder="Phone Number*" pattern="[0-9]{3} [0-9]{3} [0-9]{4}" 
                onChange={handleInputChange}
                required />
              <Input type="email" name="email" placeholder="E-mail" onChange={handleInputChange} />
              <CheckboxContent>
                <Description>Select any relevant skills that you have experience with (optional):</Description>                

                <div className="mt-2">  
                  {jobs.map(({name},index)=>{
                    return(
                      <label className="container" key={index}>{name}
                        <input type="checkbox" value={name} 
                          checked={jobCheckedState[index]}
                          onChange={() => handleJobChange(index)}
                        />
                        <span className="checkmark"></span>
                      </label>    
                    )
                  })}
                  {/* <CheckInput type="text" name="other skill" placeholder="other" /> */}
                </div>
              </CheckboxContent>
              
              <CheckboxContent>
                <Description className="mt-2">Your availability: </Description>                
                <div className="mt-2">
                  {availability.map(({name},index)=>{
                      return(
                        <label className="container" key={index}>{name}
                          <input type="checkbox" value={name} 
                            checked={availCheckedState[index]}
                            onChange={() => handleAvailChange(index)}
                          />
                          <span className="checkmark"></span>
                        </label>    
                      )
                    })}
                </div>
              </CheckboxContent>
              <Input type="text" name="zipcode" placeholder="Zip Code*" 
                onChange={handleInputChange}
              />
              <SubmitButton type="submit" onClick={(e)=>formSubmit(e)}>{submitButtonText}</SubmitButton>
                    {error && <Error>Please check Name, Phone Number and Zip Code.</Error>}
            </Form>
          </TextContent>
          }
        </TextColumn>
        <ImageColumn>
          <Image imageSrc={EnvIllustrationSrc} />
        </ImageColumn>
    
      </TwoColumn>
    </Container>
  );
};

export const jobs = [
  {
    name: "ForkLift Operation",
  }, {
    name: "Warehouse Shipping/Receiving",
  }, {
    name: "Manual Machining",
  }, {
    name: "CNC Machining",
  }, {
    name: "Tool & Die",
  }, {
    name: "Assembly Work",
  }, {
    name: "Quality Inspection",
  },
    {
      name: "Maintenance Tech",
    }
  ];

export const availability = [
  {
    name:"Available for Full Time work"
  },
  {
    name:"Need Flexible shifts"
  }
]

export const workersCount = [
  {
    count:"Under 100"
  },
  {
    count:"100 - 200"
  },
  {
    count:"Over 200"
  }
]
  
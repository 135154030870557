import React,{useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
// import Header, { NavLinks, NavLink, PrimaryLink} from "components/headers/light.js";
// import { Link } from "react-router-dom";
import f1 from '../images/site/f1.jpg'
import f2 from '../images/site/f2.jpg'
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Faq from '../components/faqs/SingleCol'
import Hero from "components/hero/BackgroundWorker.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
//import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
//import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

//  import SupportIconImage from "images/support-icon.svg";
//  import ShieldIconImage from "images/shield-icon.svg";
//  import CustomerLoveIconImage from "images/simple-icon.svg";
// import GetStarted from "components/cta/GetStartedLight.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const faq=[
  {
    question: "Who is a Flex worker?",
    answer:
      "After you hire and train this employee, you let them pick which shifts they are able to work."
  },
  {
    question: "Is this a new innovation? ",
    answer:
      "No. In the 1970s the manufacturing industry widely used job sharing programs. This was done by having two employees sharing one job. While a great first step towards flexibility, this was not scalable beyond a pair of employees. Thanks to technology and smartphone adoption, we can offer and scale such flexibility in industrial manufacturing again."
  },
  {
    question: "How will Gig and Take support such flexibility?",
    answer:
      "Factories and warehouses are struggling with unprecedented attendance and staffing challenges. To address the changing preferences of the workforce, the easy-to-use technology tools provided by Gig and Take bring reliability and predictability to your front line supervisors for their daily staffing needs."
  },
]

export default ({
  des=(
    <>
    After hiring and training, workers need more control over their schedule. On the other hand, as the employer, you expect predictability and reliability in meeting your labor demands. Thanks to technology, these do <span tw="font-bold">not</span> have to be mutually exclusive goals.
    </>)
}) => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  // const navLinks = [
  //   <NavLinks key={1}>
  //     <Link to="/"><NavLink href="/#">Home</NavLink></Link>
  //     <Link to="/companies"><NavLink href="/#">Companies</NavLink></Link>
  //     <Link to="/workers"><NavLink href="/#">Workers</NavLink></Link>
  //     {/* <Link to="/blog"><NavLink href="/#">Blog</NavLink></Link> */}
  //   </NavLinks>,
  //   <NavLinks key={2}>
  //   <PrimaryLink href="https://app.gigandtake.com/">
  //     Sign In
  //   </PrimaryLink>
  // </NavLinks>
  // ];

  return (
    <AnimationRevealPage disabled={true}>
      {/* <Header links={navLinks} /> */}
      <Hero />
      <ContactUsForm />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="Gig and Take helps you create and manage a (truly) flexible workforce."
        description="Historically, we viewed flexibility as (example) 80% full time and 20% temp. It is time to update that equation to include ~10% workers who are (truly) flexible."
      /> */}
      {/* <GetStarted /> */}
      <MainFeature1
        subheading={<Subheading>US Labor Shortage</Subheading>}
        heading="The expectations of your candidate pool have changed."
        description="Today’s “essential workers” demand flexibility. After these recent unprecedented years, a traditional 8-hours a day, 5-days a week schedule does not work for them anymore. Over 4 million workers across the US have still not returned to work since the start of the pandemic."
        imageSrc={f1}
      />
      <MainFeature1
        subheading={<Subheading>What can companies do?</Subheading>}
        heading="Rethink how certain jobs are designed."
        // description = (
        //   <>
        //     Work. <span tw="text-primary-500">When you can.</span>
        //   </>
        // )
        description={des}
        imageSrc={f2}
        textOnLeft={false}
      />
      
      <Faq 
        subheading = "FAQs"
        heading = "You have Questions ?"
        description = "And we have got answers to all of them."
        faqs = {faq}
      /> 
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
      </AnimationRevealPage>

  );
};
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import {Link} from 'react-router-dom'
import pic from '../../images/site/p21.jpg'

const StyledHeader = styled(Header)`
  ${tw`lg:max-w-none w-full lg:fixed sm:max-w-full sm:fixed lg:inset-x-0 lg:bg-black`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300 mb-4`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full sm:mt-16 md:mt-16`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url(${pic});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

//const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <Link to="/"><NavLink href="/#">Home</NavLink></Link>
      <Link to="/companies"><NavLink href="/#">Companies</NavLink></Link>
      <Link to="/workers"><NavLink href="/#">Workers</NavLink></Link>
      <Link to="/news"><NavLink href="/#">News</NavLink></Link>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink>
      <Link to="https://app.gigandtake.com/">
        Sign In
        </Link>
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
              We help you create & manage a
              <br />
              (truly) flexible workforce.
          </Heading>
        </Content>
        
      </HeroContainer>
    </Container>
  );
};

import React,{useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
// import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
//import ContactDetails from "components/cards/ThreeColContactDetails.js";
import Faq from '../components/faqs/SingleCol'
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Features from "components/features/ThreeColSimple.js";
import Hero from "components/hero/BackgroundWorker.js";
// import Features1 from "components/features/ThreeColSimple.js";
// import Features2 from "components/features/ThreeColWithSideImage.js";
// import Features3 from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features4 from "components/features/VerticalWithAlternateImageAndText.js";
// import Features5 from "components/features/DashedBorderSixFeatures";
// import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature4 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
//  import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// // import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
// import { NavLink, NavLinks,PrimaryLink } from "components/headers/light.js";
// //import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";

// import { Link } from "react-router-dom";
//const Header = tw(HeaderBase)`max-w-none`;
// const StyledHeader = styled(Header)`
//   ${tw`sm:max-w-none w-full lg:fixed sm:max-w-full sm:fixed lg:inset-x-0 mt-0`}
// `;
// const Address = tw.span`leading-relaxed`;
// const AddressLine = tw.span`block`;
// const Email = tw.span`text-sm mt-6 block text-gray-500`;
// const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const faq = [
  {
    question: "How is this different from any other job?",
    answer:
      "Unlike other factory or warehouse jobs, you pick and choose the hours you are able to work."
  },
  {
    question: "Do I have to work a set number of hours every day or every week?",
    answer:
      "No. You decide how many hours you are able to work in a day or week."
  },
  {
    question: "What do I need to sign up?",
    answer:
      "If you are qualified to work in a factory or a warehouse and use a smartphone, you are good to go!"
  },
  {
    question: "Where is this available in the US?",
    answer:
      "We are initially launching in central Pennsylvania but feel free to sign up. We will let you know when these flexible jobs are available in your area."
  },
  {
    question: "How does this work?",
    answer:
      "If you are qualified and are hired by a company, they will inform you about availability of shifts on a given day / week, and you are able to sign up for these using your smartphone."
  },
  {
    question: "Is this considered Full-Time Work?",
    answer:
      "Considering the kind of flexibility and hours offered, this will not likely amount to full-time work."
  },
]

export default () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  // const navLinks = [
  //   <NavLinks key={1}>
  //     <Link to="/"><NavLink href="/#">Home</NavLink></Link>
  //     <Link to="/companies"><NavLink href="/#">Companies</NavLink></Link>
  //     <Link to="/workers"><NavLink href="/#">Workers</NavLink></Link>
  //     {/* <Link to="/blog"><NavLink href="/#">Blog</NavLink></Link> */}
  //   </NavLinks>,
  //   <NavLinks key={2}>
  //     <PrimaryLink href="/#">
  //     <Link to="https://app.gigandtake.com/">
  //       Sign In
  //       </Link>
  //     </PrimaryLink>
  //   </NavLinks>
  // ];
  return (
     <AnimationRevealPage disabled={true}>
       
      {/* <Header links={navLinks} />
      <Features
        description="A 40-hour week is not an option in your personal life. We understand. Not everyone can come to work 8-hours a day. Or, even every day of the week. Work (only) when you can!"
      /> */}
      <Hero />
      <FeatureWithSteps />
      <ContactUsForm />
      <Faq 
        subheading = "FAQs"
        heading = "You have Questions ?"
        description = "And we have got answers to all of them."
        faqs = {faq}
      />
      {/* <ContactDetails
        cards={[
          {
            title: "New York",
            description: (
              <>
                <Address>
                  <AddressLine>40 Gates Court</AddressLine>
                  <AddressLine>Endicott, NY 13760</AddressLine>
                </Address>
                <Email>contact@treact.com</Email>
                <Phone>+1 (203) 991-6988</Phone>
              </>
            )
          },
        ]}
      /> */}
      <Footer />
    
    </AnimationRevealPage>
  );
};
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, NavToggle } from "../headers/light.js";
import {Link} from 'react-router-dom'

// ${tw`lg:max-w-none w-full lg:fixed backdrop-filter backdrop-invert-0 sm:max-w-full sm:fixed lg:inset-x-0 lg:bg-black`}
const StyledHeader = styled(Header)`
  ${tw`lg:max-w-none w-full lg:fixed sm:max-w-full sm:fixed md:max-w-full md:fixed lg:inset-x-0 lg:bg-white`}
  
  ${NavToggle}.closed {
    ${tw`text-primary-500 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover `}
 
`;

// const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-50 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;

// const Heading = styled.h1`
//   ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
//   span {
//     ${tw`inline-block mt-2`}
//   }
// `;

//const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <Link to="/"><NavLink href="/#">Home</NavLink></Link>
      <Link to="/companies"><NavLink href="/#">Companies</NavLink></Link>
      <Link to="/workers"><NavLink href="/#">Workers</NavLink></Link>
      <Link to="/news"><NavLink href="/#">News</NavLink></Link>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink>
      <Link to="https://app.gigandtake.com/">
        Sign In
        </Link>
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <HeroContainer>
        <StyledHeader links={navLinks} />   
      </HeroContainer>
    </Container>
  );
};

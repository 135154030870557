import React from "react";
//import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ContentWithPaddingXl } from "components/misc/Layouts.js";
//import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
//import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import { useLocation } from "react-router-dom";

const Heading = tw(SectionHeading)``;
//const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto text-2xl max-w-screen-lg`;
// const ThreeColumnContainer = styled.div`
//   ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
// `;
// const Column = styled.div`
//   ${tw`lg:w-1/3 max-w-xs`}
// `;

// const Card = styled.a`
//   ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
//   .imageContainer {
//     ${tw`text-center rounded-full p-4 bg-gray-100`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }

//   .title {
//     ${tw`mt-4 font-bold text-xl leading-none`}
//   }

//   .description {
//     ${tw`mt-4 text-sm font-medium text-secondary-300`}
//   }

//   .link {
//     ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
//     .icon {
//       ${tw`ml-2 w-4`}
//     }
//   }
// `;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
// `;
export default ({
  cards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security.",
      url: "https://timerse.com"
    },
    {
      imageSrc: SupportIconImage,
      title: "24/7 Support",
      description: "Lorem ipsum donor amet siti ceali placeholder text",
      url: "https://google.com"
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Customizable",
      description: "Lorem ipsum donor amet siti ceali placeholder text",
      url: "https://reddit.com"
    }
  ],
  linkText = "Learn More",
  heading = "",
  heading1=(<>Can't work every day? <span tw="text-primary-500">Work when you can.</span></>),
  heading2=(<>Gig and Take helps you create and manage a (truly) <span tw="text-primary-500">Flexible Workforce.</span></>),
  subheading = "",
  description2="Historically, we viewed flexibility as (example) 80% full time and 20% temp. It is time to update that equation to include ~10% workers who are (truly) flexible.",
  description1="A 40-hour week is not an option in your personal life. We understand. Not everyone can come to work 8-hours a day. Or, even every day of the week. Work (only) when you can!",
  imageContainerCss = null,
  imageCss = null
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  const location = useLocation()
  const h = location.pathname === '/workers' ? heading1 : heading2
  const d = location.pathname === '/workers' ? description1 : description2
   

  return (
    // <Container>
      <>
      <ContentWithPaddingXl>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        {h && <Heading>{h}</Heading>}
        {d && <Description>{d}</Description>}
        {/* <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer> */}
      </ContentWithPaddingXl>
      {/* <DecoratorBlob /> */}
     </> 
    // </Container>
  );
};

import React,{useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
import axios from "axios";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { workersCount } from "utils/jobs";
import "./form.css"


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-100 text-gray-700 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-700 text-base font-medium tracking-wide border-b-2 py-2 text-gray-700 hocus:border-primary-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Error = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-red-700`
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const CheckboxContent = tw.div`mt-3 block`;
const Description = tw.p`mt-4 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;

//const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`
const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default ({
  description = <><span tw="text-primary-500">Thanks! </span><wbr/> We will reach you soon.</>
}) => {

  const [group,setGroup] = useState([])
  const [error,setError] = useState(false)
  const [data,setData] = useState({company:"",email:""})

  const [countCheckedState, setCountCheckedState] = useState(
    new Array(workersCount.length).fill(false)
  );
  const [isSubmit,setIsSubmit] = useState(false)

  const handleInputChange = (e) => {
    setData({...data,[e.target.name]:e.target.value})
  }

  const handleCountChange = (position) => {
    const updatedCheckedState = countCheckedState.map((item, index) =>
      index === position ? !item : item
    );
    setCountCheckedState(updatedCheckedState);
    
    const newCount = updatedCheckedState.reduce(
      (arr, currentState, index) => {
        if (currentState === true) {
          return [...arr,workersCount[index].count];
        }
        return arr;
      },
      []
    );
    setGroup(newCount)
  }
  // console.log(group)

  const formSubmit = async(e) => {
    e.preventDefault()
      if(data['company'].length===0 || data['email'].length===0){
        console.log("hjh",data.company,data.email)
        setError(true)
      }else{
        const val = {...data,group:group,datetime:new Date().toLocaleString()}
        // console.log("submit",val)
        try {
          const res = await axios.post('https://sheet.best/api/sheets/e0d5dbb4-b8fa-4c76-8bf1-05800e027c00',val)
          // console.log(res)
          if(res.status===200) setIsSubmit(true)
          setData({...data,company:"",email:""})
          setError(false)
        } catch (error) {
          console.error(error)
        }
      }
  }

  return (
    <Container>
      <Content>
        <FormContainer>
        {isSubmit && <TextContent><Heading>{description}</Heading></TextContent>}
          {!isSubmit &&
          <div tw="mx-auto max-w-4xl">
            <h2>Register</h2>
            <Description>We are taking a limited number of <span style={{color:"#f93"}}>early customers.</span></Description> 
            <Description>Register here for the remaining 5 slots.</Description>
            <div>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Company Name</Label>
                    <Input id="name-input" type="text" name="company" onChange={handleInputChange} placeholder="E.g. John Doe" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="email" onChange={handleInputChange} placeholder="E.g. john@mail.com" />
                  </InputContainer>
                </Column>
                <CheckboxContent>
                <Description className="mt-2">Average number of workers in your factory/warehouse:</Description>                
                <div className="mt-2">
                  {workersCount.map(({count},index)=>{
                      return(
                        <label className="container" key={index}>{count}
                          <input type="checkbox" value={count} 
                            checked={countCheckedState[index]}
                            onChange={() => handleCountChange(index)}
                          />
                          <span className="checkmark"></span>
                        </label>    
                      )
                    })}
                </div>
              </CheckboxContent>
              </TwoColumn>

              <SubmitButton type="submit" onClick={(e)=>formSubmit(e)} value="Submit">Submit</SubmitButton>
              {error && <Error>Please check Company name and email.</Error>}
            </div>
          </div>}
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};

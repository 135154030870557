import React,{useEffect} from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColWithSideImage.js";


// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
 
//import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
// import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import TwoColButton from "components/features/TwoColButton";
//import SimpleSubscribeNewsletter from "components/forms/SimpleSubscribeNewsletter";

const HighlightedText = tw.span`text-primary-500`



export default () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
   <AnimationRevealPage disabled={true}>
  
    <Hero />
   
  <Features 
        heading={<>Reimagine the future of <HighlightedText>Industrial Work.</HighlightedText></>}
      />
      

    <Footer />

    </AnimationRevealPage>
    
);
}

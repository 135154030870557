import React,{useEffect} from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/hero/HeaderOnly.js";
import styled from "styled-components";
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Meta from "components/meta/Metatags.js";



const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
   <AnimationRevealPage disabled={true}>
    <Meta />
    <Header />
    <div id="news"> <br />
    <br />   <StyledResponsiveVideoEmbed
      url="https://www.youtube.com/embed/YFcsSEDJfrc?controls=0"
      background="transparent"
    /></div>
    <Footer />

    </AnimationRevealPage>
);
}

import React,{useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Header from "components/hero/HeaderOnly.js";


export default () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
     <AnimationRevealPage disabled={true}>
       <Header />
      <ContactUsForm />
      <Footer />
    
    </AnimationRevealPage>
  );
};